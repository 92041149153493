<template>
    <a-card>
        <a-page-header
            title='巨量产品库详情'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="车系">
                        <a-select
                            mode="multiple"
                            v-model="form.seriesIds"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="产品可投放状态">
                        <a-select
                            v-model="form.productStatus"
                            placeholder="请选择车系"
                            allow-clear
                        >
                            <a-select-option key="1">可投放</a-select-option>
                            <a-select-option key="0">不可投放</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col span="14">
                    <a-space>
                        <div>获取最新产品</div>
                        <a-input-number v-model="adPlatformAccountId" placeholder="输入巨量广告的平台账套ID" :precision="0" style="width: 300px;" />
                        <a-button type="primary" @click="updateByPlatformAccountId">提交</a-button>
                    </a-space>
                </a-col>
                <a-col span="10" class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.hjkAdVideoId"
            :pagination='false'
            bordered
        >
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-40">PS：产品库每30分钟更新1次</div>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '产品',
                    dataIndex: 'productName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '产品库',
                    dataIndex: 'productPlatformName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '产品投放状态',
                    customRender: (text, row, index) => {
                        return row.productStatus == 1 ? '可投放' : '不可投放'
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    customRender: (text, row, index) => {
                        return <div>
                                <div>{ row.advertiserName || '-' }</div>
                                <div class="txt">{row.adPlatformAccountId || '-'}</div>
                            </div>
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            seriesList: [],
            adPlatformAccountId: '',
        }
    },
    created() {
        this.getPrincipalSeriesList()
        this.getDataList()
    },
    methods: {
        getPrincipalSeriesList() {
            let params = {
                principalIds: [],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = Object.assign(_form, {
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.materialManage.getProductLibraryPage(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        updateByPlatformAccountId() {
            if(!this.adPlatformAccountId) {
                this.$message.error('请输入巨量广告的平台账套ID')
                return
            } else {
                this.$api.core.materialManage.updateByPlatformAccountId({
                    adPlatformAccountId: this.adPlatformAccountId
                }).then((res) => {
                    if(res.code == 200) {
                        this.$message.success('操作成功，建议10分钟后查看')
                    } else {
                        this.$message.error(`${res.message}`)
                    }
                })
            }
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.txt {
    color: #ccc;
}
</style>